<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="end">
        <!-- <el-col v-if="false">
          <el-button
            plain
            class="add-column-btn bbox"
            @click="gotoDetail('')"
            type="primary"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col> -->
        <el-col :span="10">
          <el-select
            class="input_box"
            v-model="queryInfo.condition.examineStatus"
            filterable
            @change="selectCheck"
            placeholder="请选择审批状态"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <div class="flex align-center justify-end header-search-box">
          <el-input
            placeholder="请输入运营商地名"
            clearable
            v-model="searchInput"
            class="header-search-input ml10"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </el-input>
        </div>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="运营商头像" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.operatorLogo" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <el-table-column label="运营商地名" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.operatorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.contactsPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="身份证正面" align="center" prop="">
          <template slot-scope="scope">
            <img :src="scope.row.idCardFrontSide" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <el-table-column label="身份证反面" align="center" prop="">
          <template slot-scope="scope">
            <img :src="scope.row.idCardSecondSide" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <!-- <el-table-column label="创建时间" align="center" prop="税号">
          <template slot-scope="scope">
            <span>{{ scope.row.createDt }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="微信号" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="意向城市" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="合作类型" align="center" prop="">
          <template slot-scope="scope">
            <span>{{ scope.row.contacts }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="状态" align="center" prop="">
          <template slot-scope="scope">
            <span v-if="scope.row.examineStatus == 0">未审批</span>
            <span v-if="scope.row.examineStatus == 1">已审批</span>
            <span v-if="scope.row.examineStatus == 2">审批拒绝</span>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center" prop="税号">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id)"
              ></i>
            </el-tooltip>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip> -->
          </template>
          <!-- <template slot-scope="scope">
                        <el-button type="primary" @click="dialogVisible = true">审批</el-button>

                        <el-dialog
                          title="提示"
                          :visible.sync="dialogVisible"
                          width="30%"
                          :before-close="handleClose"
                          center>
                          <span>确定审批通过吗</span>
                          <span slot="footer" class="dialog-footer">
                             <el-button @click="dialogVisible = false">取 消</el-button>
                             <el-button type="primary" @click="judgeDetail(scope.row.id)">确 定</el-button>
                        </span>
                        </el-dialog>
                        <el-button type="primary" @click="dialogVisible = true">审批</el-button>
                        <el-dialog title="修改收益比率" :visible.sync="dialogFormVisible">
                          <el-form :model="form">
                          <el-form-item label="收益比率" :label-width="formLabelWidth">
                          <el-input v-model="form.name" autocomplete="off"></el-input>
                          </el-form-item>
                          </el-form>
                            <div slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormVisible = false">取 消</el-button>
                            <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                          </div>
                        </el-dialog>
                        
                    </template> -->
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: "未审批"
        },
        {
          value: 1,
          label: "已拒绝"
        },
        {
          value: 2,
          label: "审批拒绝"
        }
      ],
      height: window.innerHeight - 256, //表格高度
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,

        //查询条件
        condition: {
          // id: "",
          examineStatus: 0,
          queryKey: ""
        }
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      dialogVisible: false,
      // addDialogVisible: false,
      // dialogFormVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: ""
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    // this.queryInfo.currPage = this.$route.query.currPage;
    // this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    judgeStatus(examineStatus) {
      if (examineStatus === 0) {
        return "未审批";
      } else if (examineStatus === 1) {
        return "已审批";
      } else {
        return "审批拒绝";
      }
    },
    // 条件查询
    search() {
      this.queryInfo.condition.queryKey = this.searchInput;
      this.getListData();
      // console.log("------")
      // console.log(this.queryInfo.condition.queryKey)
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },
    selectCheck(value) {
      let that = this;
      that.queryInfo.condition.examineStatus = value;
      that.$http
        .post("/userOperator/list", that.queryInfo)
        .then(function(response) {
          // console.log(response)
          if (response.data.code == 200) {
            that.tableData = response.data.data.data;
            console.log(that.tableData);
            // that.tableData = that.tableData.filter(
            //   (tableData) =>
            //     tableData.examineStatus == 2 || tableData.examineStatus == 0
            // );
          }
        });
    },
    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/operator/detail",
        query: {
          id: id,

          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize
        }
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/userOperator/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      console.log(that.queryInfo);
      that.$http
        .post("/userOperator/list", that.queryInfo)
        .then(function(response) {
          // console.log(that.queryInfo.condition.queryKey);
          if (response.data.code == 200) {
            // that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
            console.log(that.tableData);
            // that.tableData = that.tableData.filter(
            //   (tableData) =>
            //     tableData.examineStatus == 2 || tableData.examineStatus == 0
            // );
            that.total = that.tableData.length;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.input_box {
  // padding-top: 10px;
  margin-left: 70%;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
